<template>
  <section>
    <section class="info-container">
      <section class="info-item-wrap">
        <p class="info-title">人员信息</p>
        <a-row
          class="m-t-18"
          type="flex"
          style="
            border: 1px solid #629ff0;
            padding: 20px 20px 0 20px;
            border-radius: 8px;
            text-align: center;
          "
        >
          <a-col style="width: 100%">
            <a-row>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">单位名称</a-col>
                  <a-col class="val">{{detailInfo.companyName}}</a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">单位类型</a-col>
                  <a-col class="val">{{detailInfo.companyTypeName}}</a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">人员类型</a-col>
                  <a-col class="val">{{detailInfo.personnelType}}</a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">职位工种</a-col>
                  <a-col class="val">{{detailInfo.positionWorkName}}</a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">人员姓名</a-col>
                  <a-col class="val">{{detailInfo.personnelName}}</a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">登记时间</a-col>
                  <a-col class="val">{{detailInfo.inDate}}</a-col>
                </a-row>
              </a-col>
              <a-col :span="6">
                <a-row class="info-item">
                  <a-col class="key">联系方式</a-col>
                  <a-col class="val">{{detailInfo.phoneNumber}}</a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <section class="info-item-wrap">
        <p class="info-title">拉黑说明</p>
        <a-row class="p-t-14">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key"> 有效期限 </a-col>
              <a-col class="val"> {{detailInfo.validityDate}} </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row class="p-t-14">
          <a-col :span="8">
            <a-row class="info-item">
              <a-col class="key"> 布控说明 </a-col>
              <a-col class="val"> {{detailInfo.controlDesc}} </a-col>
            </a-row>
          </a-col>
        </a-row>
      </section>
      <a-form class="form-container" :form="form">
        <section class="info-item-wrap">
          <p class="info-title">解除限制</p>
          <a-row>
            <a-col :span="8">
              <a-form-item label="解除时间">
                <div style="display: flex; align-items: center">
                  <a-date-picker
                    ref="inDate"
                    placeholder="请选择"
                    :disabled-date="disabledDate"
                    v-decorator="[
                      'relieveDate',
                      {
                        rules: [
                          {
                            required: true,
                            message: '请选择解除时间',
                          },
                        ],
                      },
                    ]"
                  />
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-item label="解除说明">
                <a-textarea
                  rows="4"
                  placeholder="请输入"
                  v-decorator="[
                    'relieveDesc',
                    {
                      rules: [
                        { required: false, message: '请输入解除说明' },
                        { max: 150, message: '最大输入150个字符' },
                      ],
                    },
                  ]"
                ></a-textarea>
              </a-form-item>
            </a-col>
          </a-row>
        </section>
        <section class="form-item m-t-10">
        <div class="form-btn-group" style="text-align: center; padding: 18px 0">
          <a-button
            @click="submit"
            :disabled="submitLoadding"
            :loading="submitLoadding"
          >
            <a-icon type="save" v-if="!submitLoadding" />保存
          </a-button>
          <a-button @click="$router.back()">取消</a-button>
        </div>
      </section>
      </a-form>
    </section>
  </section>
</template>

<script>
// import previewImage from '@/components/previewImage'
import moment from "moment";
export default {
  data() {
    return {
      detailInfo: {},
      listMap: [],
    };
  },
  // components:{previewImage},
  computed: {
    did() {
      return this.$route.query.id;
    },
    submitLoadding() {
      return this.$store.state.submitLoadding;
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created() {
    this.queryBlackDetails()
  },
  methods: {
    disabledDate(current) {
				// return current && current <= moment().endOf('day')
        return current && current < moment().subtract(1, 'day'); 
			},
     queryBlackDetails() {
      this.$api.queryBlackDetails(this.did).then((res) => {
        if (res.code === 200) {
          this.detailInfo = (res.data) || {};
          this.detailInfo.personnelType = this.detailInfo.personnelType == 1?'管理人员':'劳务人员';
          this.detailInfo.createTime = moment(this.detailInfo.createTime).format("YYYY-MM-DD");
          if(this.detailInfo.validityDate==-1){
            this.detailInfo.validityDate = '永久';
          }else{
            this.detailInfo.validityDate = this.detailInfo.createTime + "~" + this.detailInfo.validityDate;
          }
        }
      });
    },
    submit() {
      this.form.validateFields((err, formData) => {
        if (!err) {
          this.$store.commit("SET_SUBMITLOADDING", true);
          formData.relieveDate = formData.relieveDate
            ? formData.relieveDate.format("YYYY-MM-DD")
            : null;
          formData.id = this.did;
          this.$api.relieveBlack(formData).then((res) => {
            if (res.code === 200) {
              this.$router.back();
            }else{
              this.$message.error(res.msg);
            }
          });
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.info-item .val {
  text-align: start;
}
.textVal {
  height: 44px;
  line-height: 44px;
}
</style>